export const getVersionFromUrn = (urn: string) => {
  if (!urn) return '';
  const REGEX = /[^,:]*$/;
  const version = REGEX.exec(urn);

  if (version) {
    return version[0];
  }

  return '';
};

export const getBrickVersionFromUrn = (urn: string) => {
  if (!urn) return '';

  const splittedUrn = urn.split(':');
  const packageName = splittedUrn[splittedUrn.length - 3];
  const version = splittedUrn[splittedUrn.length - 2];
  if (version && packageName) {
    const res = `${packageName} (${version})`;
    return res;
  }

  return '';
};
